import React from 'react'
import Header from './Header'
import demos from '../Assets/Assests/e36052bf43b88754f404ebe2d4a7ddad 3.svg'
import tours from '../Assets/Assests/image 9.svg'
import walkthroughs from '../Assets/Assests/b4ca1b_e0a4b33ff89441f0b42d42466d4bd920~mv2 3.svg'
import aimuseum from '../Assets/Assests/Group 1171276004.png'
import happy from '../Assets/Assests/happy 1.svg'
import NGO from '../Assets/Assests/icons Ai/beb9b248c34ce16aee92a1a185f8c5a1 1.svg'
import rural from '../Assets/Assests/icons Ai/583f7520679751ca606a246aa4e4cb2e 1.svg'
import school from '../Assets/Assests/icons Ai/front-view-boy-holding-laptop-while-wearing-virtual-reality-headset 1.png'
import college from '../Assets/Assests/icons Ai/multiracial-team-adult-students-wearing-vr-headset 1.png'
import professional from '../Assets/Assests/icons Ai/startup-employee-looking-business-charts-using-ai-software 1.png'
import women from '../Assets/Assests/icons Ai/workers-using-ai-computing-simulation 1.png'
import '../Styles/LearnInnovate.css'

const LearnInnovate = () => {
  const programs = [
    {
      title: 'For Schools',
      tagline: 'Igniting Young Minds: Where Curiosity Meets Possibility.',
      Description:
        'Field trips, classroom labs, and teacher training sessions that instill an early love for AI.',
      image: school,
      alt: 'Student using VR headset with mathematical equations',
    },
    {
      title: 'For Colleges & Universities',
      tagline:
        'Fueling Academic Excellence: Collaboration at the Crossroads of Theory and Practice.',
      Description:
        'Joint research projects, symposiums, student ambassador programs, and credit-eligible courses.',
      image: college,
      alt: 'Students working with VR technology',
    },
    {
      title: 'For Working Professionals',
      tagline: 'Upskill to Outshine: Stay Ahead in the Age of AI.',
      Description:
        'Evening and weekend programs to balance with work schedules, culminating in globally recognized certifications.',
      image: professional,
      alt: 'Professional working with digital visualizations',
    },
    {
      title: " For NGO's & Social Impact",
      tagline:
        'Technology as a Force for Good—Empower Communities, Uplift Lives.',
      Description:
        'Join or initiate programs addressing education, healthcare, disaster relief, and environmental sustainability.',
      image: NGO,
      alt: 'Futuristic cityscape illustration',
    },
    {
      title: 'Women in AI',
      tagline: 'Change the Ratio, Shift the Future.',
      Description:
        ' Scholarships, mentorship, networking events to encourage women’s participation and leadership in AI.',
      image: women,
      alt: 'People examining AI data visualizations',
    },
    {
      title: 'Rural Outreach',
      tagline: 'Bridging the Digital Divide: AI in Every Corner of the Nation.',
      Description:
        'On-ground training camps, digital literacy drives, and local language AI applications.',
      image: rural,
      alt: 'AI vs human intelligence visualization',
    },
  ]

  return (
    <div className='about-page bg-dark text-white'>
      {/* Navigation */}
      <Header />

      {/* Vision & Mission Section */}
      <section className='vision-section'>
        <div className='container position-relative'>
          <div className='row'>
            <div className='col-lg-8'>
              <p className='lead mt-5'>AI & Robotics</p>
              <h1 className='display-3 mb-4'>
                Empowering the Future of AI: Learn, Innovate, Transform
              </h1>
              <p className='lead'>
                Unlock the potential of artificial intelligence through
                continuous learning and groundbreaking innovation. Stay ahead
                with cutting-edge technologies, from machine learning to
                generative AI, and be a part of the transformation shaping
                industries, solving global challenges, and creating the next big
                thing in tech. Join the AI revolution!
              </p>
            </div>
          </div>
          <div className='geometric-shapes'></div>
        </div>
      </section>

      <section className='history-section py-5'>
        <div className='container'>
          <h2 className='text-center mb-5'>Learning & Developmemt</h2>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <div className='future-fusion-card p-4 rounded-lg'>
                <div className='d-flex align-items-center mb-3'>
                  <div className='icon-circle me-3 d-flex justify-content-center align-items-center"'>
                    <img src={happy} alt='happy' />
                  </div>
                  <h3 className='mb-0'>AI Courses & Workshops</h3>
                </div>
                <p className='subtext'>
                  Learn, Create, Innovate—Unlock the Power of AI.
                </p>

                <p>
                  <ol>
                    <li>
                      <b>Beginner Workshops:</b> “AI 101: Start Your Journey” –
                      Introduction to coding, machine learning fundamentals.
                    </li>
                    <li>
                      <b>Advanced Masterclasses:</b> “Deep Dive Into Deep
                      Learning” – For professionals seeking specialized skills
                      in NLP, computer vision, etc.
                    </li>
                  </ol>
                </p>
              </div>
            </div>
            <div className='col-lg-6'>
              <img
                src={aimuseum}
                alt='AI Museum'
                className='img-fluid rounded-lg'
              />
            </div>
          </div>
        </div>
      </section>

      <section className='history-section py-5'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <img
                src={demos}
                alt='Interactive demos'
                className='img-fluid rounded-lg'
              />
            </div>
            <div className='col-lg-6'>
              <div className='future-fusion-card p-4 rounded-lg'>
                <div className='d-flex align-items-center mb-3'>
                  <div className='icon-circle me-3 d-flex justify-content-center align-items-center"'>
                    <img src={happy} alt='happy' />
                  </div>
                  <h3 className='mb-0'>Skill Development Programs</h3>
                </div>

                <h4>Grow Your AI Arsenal—Shape the Future of Tech.</h4>
                <p>
                  Earn micro-credentials, digital badges, or professional
                  certifications backed by credits and hands-on projects /
                  mentorship that accelerate your career.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='history-section py-5'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <div className='future-fusion-card p-4 rounded-lg'>
                <div className='d-flex align-items-center mb-3'>
                  <div className='icon-circle me-3 d-flex justify-content-center align-items-center"'>
                    <img src={happy} alt='happy' />
                  </div>
                  <h3 className='mb-0'>Internship & Apprenticeships</h3>
                </div>
                <h4>Real-World AI, Real-World Impact.</h4>
                <p>
                  Collaborate on live projects in AI labs, industry
                  partnerships, or museum-led community initiatives. Gain mentor
                  guidance, hands-on experience, and a launchpad to a
                  flourishing AI career.
                </p>
              </div>
            </div>
            <div className='col-lg-6'>
              <img src={tours} alt='Tours' className='img-fluid rounded-lg' />
            </div>
          </div>
        </div>
      </section>

      <section className='history-section py-5'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <img
                src={walkthroughs}
                alt='Virtual Walkthroughs'
                className='img-fluid rounded-lg'
              />
            </div>
            <div className='col-lg-6'>
              <div className='future-fusion-card p-4 rounded-lg'>
                <div className='d-flex align-items-center mb-3'>
                  <div className='icon-circle me-3 d-flex justify-content-center align-items-center"'>
                    <img src={happy} alt='happy' />
                  </div>
                  <h3 className='mb-0'>
                    AI & Value Added Programs in Schools & Colleges
                  </h3>
                </div>
                <h4>
                  Planting Seeds of Innovation: Nurturing Young Minds in AI.
                </h4>
                <p>
                  Workshops, teacher trainings, and lab resources to integrate
                  AI into the K-12 and collegiate curriculum.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='history-section py-5'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <div className='future-fusion-card p-4 rounded-lg'>
                <div className='d-flex align-items-center mb-3'>
                  <div className='icon-circle me-3 d-flex justify-content-center align-items-center"'>
                    <img src={happy} alt='happy' />
                  </div>
                  <h3 className='mb-0'>Corporate & Professional Learning</h3>
                </div>

                <p>
                  <ol>
                    <li>
                      <b>Executive Training:</b> “Lead with AI” – Specialized
                      courses for business leaders focusing on strategy, ethics,
                      and implementation.
                    </li>

                    <li>
                      <b>Customized Programs:</b> Tailored courses for
                      corporations seeking AI-savvy workforces.
                    </li>
                  </ol>
                </p>
              </div>
            </div>
            <div className='col-lg-6'>
              <img src={tours} alt='Tours' className='img-fluid rounded-lg' />
            </div>
          </div>
        </div>
      </section>

      <section
        className='py-5'
        style={{
          background:
            'radial-gradient(251.01% 115.64% at 50% -1.43%, rgba(0, 0, 0, 0) 82.5%, rgba(100, 51, 238, 0.8) 87.11%, #FFFFFF 100%)',
          border: '1.16px solid #664DFF',
        }}
      >
        <div className='container mb-5'>
          <h2 className='text-center text-white mb-5'>Programs</h2>
          <div className='row g-4'>
            {programs.map((program, index) => (
              <div key={index} className='col-12 col-md-6 col-lg-4 mb-4'>
                <div className='program-card h-100'>
                  <div style={{ height: '200px', overflow: 'hidden' }}>
                    <img
                      src={program.image}
                      className='program-card-img h-100 w-100'
                      alt={program.alt}
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                  <div className='program-card-body p-3 text-center'>
                    <h3 className='h5 text-white mb-3'>{program.title}</h3>
                    <h4 className='lead text-white mb-2'>{program.tagline}</h4>
                    <p className='subtext'>{program.Description}</p>
                  </div>
                  <div className='program-card-overlay position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center opacity-0 transition-opacity'>
                    <button
                      className='btn btn-outline-light'
                      aria-label={`Learn more about ${program.title}`}
                    >
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

export default LearnInnovate
