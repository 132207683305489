import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import HeroImage from '../Assets/Assests/icons Ai/woman-wearing-virtual-reality-simulator 1.svg'
import OverlayImage from '../Assets/Assests/icons Ai/robot-hand-finger-pointing-ai-technology-backgrou-2022-09-16-09-23-42-utc 1.svg'
import Header from './Header'
import Star from '../Assets/Assests/icons Ai/star.png'
import mobile_icon from '../Assets/mesume/Frame 1597884501.png'
import ai_inspired from '../Assets/mesume/Frame 1597884502.png'
import cityscape from '../Assets/mesume/Layer_1.png'
import RobotGirl from '../Assets/mesume/girl-320262_1920.png'
import elipse from '../Assets/mesume/Ellipse 2.png'
import bulb from '../Assets/mesume/el_idea.png'
import coder from '../Assets/mesume/eos-icons_machine-learning.png'
import world from '../Assets/mesume/fluent-mdl2_world.png'
import '../Styles/Main.css'
import { BsPlus } from 'react-icons/bs'
import { IoIosArrowForward } from 'react-icons/io'
import Footer from './Footer'
import face from '../Assets/mesume/Header Illustration.png'
import girl from '../Assets/Assests/Frame 1597884643.png'
import logo1 from '../Assets/Assests/Logos/Logo-6.png'
import logo2 from '../Assets/Assests/Logos/Logo-9.png'
import logo3 from '../Assets/Assests/Logos/logo3.png'
import logo4 from '../Assets/Assests/Logos/logo5.png'
import logo5 from '../Assets/Assests/Logos/logo8.png'
import logo6 from '../Assets/Assests/Logos/logo9.png'
import icon1 from '../Assets/mesume/Icon.png'
import icon2 from '../Assets/mesume/Group.png'
import icon3 from '../Assets/mesume/Icon-1.png'
import icon4 from '../Assets/mesume/Base feature icon.png'

const Main = () => {
  const stats = [
    {
      icon: icon1,
      number: '50,000+',
      label: 'Members students & professionals trained in AI fundamentals',
    },
    {
      icon: icon2,
      number: '5,000+',
      label: 'professionals certified in advanced ML and data science',
    },
    {
      icon: icon3,
      number: '200+',
      label: 'community projects powered by AI',
    },
    // {
    //   icon: icon4,
    //   number: '140',
    //   label: 'Project Partners',
    // },
  ]

  const partners = [
    {
      name: 'Sampath',
      logo: logo1,
    },
    {
      name: 'logo2',
      logo: logo2,
    },
    {
      name: 'eclipse',
      logo: logo3,
    },
    {
      name: 'clickOrder',
      logo: logo4,
    },
    {
      name: 'Techmate',
      logo: logo5,
    },
    {
      name: 'breackthrough',
      logo: logo6,
    },
  ]

  return (
    <>
      <Header />
      <div className='min-h-screen' style={{ backgroundColor: '#06071B' }}>
        <header
          className='container-fluid hero-header bg-gradient-to-br from-purple-900 to-indigo-900 text-white py-5'
          // style={{ marginTop: '80px' }}
        >
          <img
            src={Star}
            alt='star1'
            className='overlay-star position-absolute'
            style={{
              top: '120px',
              left: '40%',
              transform: 'translateX(-3%)',
              zIndex: 2,
              maxWidth: '300px',
            }}
          />

          <img
            src={Star}
            alt='star1'
            className='overlay-star-img position-absolute'
            style={{
              top: '300px',
              left: '20%',
              transform: 'translateX(-3%)',
              zIndex: 2,
              maxWidth: '300px',
            }}
          />
          <img
            src={OverlayImage}
            alt='Overlay Image'
            className='overlay-image position-absolute'
            style={{
              top: '300px',
              left: '7%',
              transform: 'translateX(-50%)',
              zIndex: 2,
              maxWidth: '300px',
            }}
          />
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <p className='mt-5'>AI & Robotics</p>
                <h1 className='fst-normal mb-4'>
                  “Experience Artificial Intelligence: <br />
                  Explore the Power of AI at the World’s First Immersive AI
                  Museum.”
                </h1>
                <p className='mb-4'>
                  Welcome to a place where technology meets imagination. Journey
                  with us through the extraordinary world of Artificial
                  Intelligence, and discover how AI is transforming communities,
                  industries, and the future itself.
                </p>
                <div className='d-flex gap-3'>
                  <button
                    className='btn px-4'
                    style={{
                      backgroundColor: '#FF7000',
                      borderColor: 'white',
                      color: 'white',
                      borderRadius: '20px',
                    }}
                  >
                    Explore the Exihibits
                    <IoIosArrowForward
                      style={{
                        marginLeft: '10px',
                      }}
                    />
                  </button>
                  <button
                    className='btn px-4'
                    style={{
                      backgroundColor: 'transparent',
                      borderColor: 'white',
                      color: 'white',
                      borderRadius: '20px',
                    }}
                  >
                    Membership
                    <IoIosArrowForward
                      style={{
                        marginLeft: '10px',
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Hero Section */}
        {/* <header
          className='container-fluid hero-header bg-gradient-to-br from-purple-900 to-indigo-900 text-white py-5'
          // style={{ marginTop: '80px' }}
        >
          <img
            src={OverlayImage}
            alt='Overlay Image'
            className='overlay-image position-absolute'
            style={{
              top: '300px',
              left: '7%',
              transform: 'translateX(-50%)',
              zIndex: 2,
              maxWidth: '300px',
            }}
          />
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <p className='mt-5'>AI & Robotics</p>
                <h1 className='fst-normal mb-4'>
                  “Experience Tomorrow, Today: <br />
                  Explore the Power of AI at the World’s First Immersive AI
                  Museum.”
                </h1>
                <p className='mb-4'>
                  Welcome to a place where technology meets imagination. Journey
                  with us through the extraordinary world of Artificial
                  Intelligence, and discover how AI is transforming communities,
                  industries, and the future itself.
                </p>
                <div className='d-flex gap-3'>
                  <button
                    className='btn px-4'
                    style={{
                      backgroundColor: '#FF7000',
                      borderColor: 'white',
                      color: 'white',
                      borderRadius: '20px',
                    }}
                  >
                    Join our Community
                    <FaGreaterThan
                      style={{
                        marginLeft: '10px',
                      }}
                    />
                  </button>
                  <button
                    className='btn px-4'
                    style={{
                      backgroundColor: 'transparent',
                      borderColor: 'white',
                      color: 'white',
                      borderRadius: '20px',
                    }}
                  >
                    Membership
                    <FaGreaterThan
                      style={{
                        marginLeft: '10px',
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header> */}
        {/* <section className='text-white'>
          <Container
            className='align-items-center justify-content-center '
            style={{
              // top: '123%',
              marginLeft: '10%', // Adjust to move further right
              zIndex: '3',
            }}
          >
            <Card
              className='text-white text-center p-4'
              style={{
                width: '90%',
                height: '380px',
                backgroundColor: '#06071B',
                fontFamily: 'Inria Serif, serif', // Font family
                fontWeight: 400, // Weight
                borderRadius: '20px',
                color: 'white', // Ensures text is visible on a dark background
                display: 'flex', // Enables flexbox
                flexDirection: 'column', // Aligns items vertically
                justifyContent: 'center', // Centers vertically
                alignItems: 'center', // Centers horizontally
                border: '2px solid white', // Adds a white border with 2px thickness
                borderRadius: '10px', // Optional: Adds rounded corners to the border
              }}
            >
              <Card.Body>
                <div
                  style={{
                    fontSize: 'clamp(16px, 2.5vw, 28px)', // Responsive font size
                    fontFamily: 'Inria Serif, serif', // Ensure a fallback font
                    textAlign: 'center', // Align text
                    borderRadius: '10px',
                    marginTop: '10px',
                  }}
                >
                  <h1>Lorem Ipsum</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Tristique amet sed
                    massa nibh lectus netus in. Aliquet donec morbi convallis
                    pretium
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    marginTop: '1rem', // Reduced distance
                    marginLeft: '3rem',
                  }}
                >
                  {[
                    {
                      icon: ai_inspired,
                      title: 'AI-inspired Artwork',
                      description:
                        'Lorem ipsum dolor sit amet consectetur. Morbi diam nisi nam diam interdum',
                    },
                    {
                      icon: cityscape,
                      title: 'Futuristic Cityscapes',
                      description:
                        'Lorem ipsum dolor sit amet consectetur. Morbi diam nisi nam diam interdum',
                    },
                    {
                      icon: mobile_icon,
                      title: 'Interactive demos',
                      description:
                        'Lorem ipsum dolor sit amet consectetur. Morbi diam nisi nam diam interdum',
                    },
                  ].map((card, index) => (
                    <div
                      key={index}
                      className='card bg-dark text-light h-100'
                      style={{
                        background:
                          'linear-gradient(90deg, #1E1244 0%, #3F1F55 100%)',
                        borderRadius: '8px',
                        width: '30%', // Adjust width to fit within parent flexbox
                        display: 'flex',
                        flexDirection: 'column',
                        // alignItems: 'center',
                        textAlign: 'center',
                        padding: '1rem',
                      }}
                    >
                      <img
                        src={card.icon}
                        alt={card.title}
                        style={{
                          width: '30px',
                          height: '10%',
                        }}
                      />
                      <h5 className='card-title'>{card.title}</h5>
                      <p className='card-text'>{card.description}</p>
                    </div>
                  ))}
                </div>
              </Card.Body>
            </Card>
          </Container>
        </section>
        <Container
          className='d-flex align-items-center justify-content-center position-absolute'
          style={{ left: '80px', marginTop: '20px' }}
        >
          <Card
            className='bg-dark text-white text-center p-4'
            style={{
              width: '90%',
              height: '250px',
              backgroundColor: '#06071B',
              fontFamily: 'Inria Serif, serif',
              fontWeight: 400,
              borderRadius: '20px',
              color: 'white',
            }}
          >
            <Card.Body>
              <Row className='align-items-center'>
           
                <Col xs={12} md={8} className='mb-3'>
                  <Card.Text
                    className='lead text-md-left text-center'
                    style={{
                      fontSize: 'clamp(16px, 2.5vw, 28px)',
                      fontFamily: 'Inria Serif, serif',
                      borderRadius: '10px',
                    }}
                  >
                    From its historical roots to its transformative impact on
                    our future, explore how AI shapes technology, society, and
                    culture.
                  </Card.Text>
                </Col>

                <Col xs={12} md={4} className='text-center d-none d-md-block'>
                  <img
                    src={RobotGirl} // Replace with actual image path
                    alt='Educational Programs'
                    width={150}
                    height={150}
                    className='img-fluid rounded-3'
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container> */}
        <section className='text-white'>
          <Container
            className='d-flex align-items-center justify-content-center'
            style={{
              // marginLeft: '10%',
              zIndex: 3,
            }}
          >
            <Card
              className='text-white text-center p-4'
              style={{
                width: '90%',
                height: 'auto', // Responsive height
                backgroundColor: '#06071B',
                fontFamily: 'Inria Serif, serif',
                fontWeight: 400,
                borderRadius: '20px',
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                border: '2px solid white',
              }}
            >
              <Card.Body>
                <div
                  style={{
                    fontSize: 'clamp(16px, 2.5vw, 28px)',
                    fontFamily: 'Inria Serif, serif',
                    textAlign: 'center',
                    borderRadius: '10px',
                    marginTop: '10px',
                  }}
                >
                  <h1
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '65px',
                      fontWeight: '600',
                      textAlign: 'center',
                    }}
                  >
                    Lorem Ipsum
                  </h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Tristique amet sed
                    massa nibh lectus netus in. Aliquet donec morbi convallis
                    pretium.
                  </p>
                </div>
                <Row
                  className='g-3 justify-content-center mt-3'
                  style={{
                    marginLeft: '3rem',
                  }}
                >
                  {[
                    {
                      icon: ai_inspired,
                      title: 'AI-inspired Artwork',
                      description:
                        'Lorem ipsum dolor sit amet consectetur. Morbi diam nisi nam diam interdum',
                    },
                    {
                      icon: cityscape,
                      title: 'Futuristic Cityscapes',
                      description:
                        'Lorem ipsum dolor sit amet consectetur. Morbi diam nisi nam diam interdum',
                    },
                    {
                      icon: mobile_icon,
                      title: 'Interactive demos',
                      description:
                        'Lorem ipsum dolor sit amet consectetur. Morbi diam nisi nam diam interdum',
                    },
                  ].map((card, index) => (
                    <Col xs={12} sm={6} md={4} key={index}>
                      <div
                        className='custom-card'
                        style={{
                          background:
                            'linear-gradient(90deg, #1E1244 0%, #3F1F55 100%)',
                          borderRadius: '8px',
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'center',
                          padding: '1rem',
                        }}
                      >
                        <img
                          src={card.icon}
                          alt={card.title}
                          style={{
                            width: '30px',
                            height: '10%',
                            margin: '0 auto 10px',
                          }}
                        />
                        <h5
                          className='card-title'
                          style={{
                            fontFamily: 'Inter',
                            fontSize: '28px',
                            fontWeight: '700',
                            lineHeight: '42px',
                          }}
                        >
                          {card.title}
                        </h5>
                        <p
                          className='card-text d-none d-md-block'
                          style={{
                            fontFamily: 'Poppins',
                            fontSize: '19px',
                            fontWeight: '400',
                            lineHeight: '28.5px',
                            letterSpacing: '0.03em',
                            textAlign: 'left',
                          }}
                        >
                          {card.description}
                        </p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Container>

          {/* First Section */}
          <Container
            className='d-flex align-items-center justify-content-center position-relative mt-5'
            style={{
              left: 0,
              padding: '0 20px',
            }}
          >
            <Card
              className='bg-dark text-white text-center p-4'
              style={{
                width: '100%',
                height: 'auto',
                backgroundColor: '#06071B',
                fontFamily: 'Inria Serif, serif',
                fontWeight: 400,
                borderRadius: '20px',
                color: 'white',
              }}
            >
              <Card.Body>
                <Row className='align-items-center'>
                  {/* Text Section */}
                  <Col xs={12} md={8} className='mb-3'>
                    <Card.Text
                      className='lead text-md-left text-center'
                      style={{
                        fontSize: 'clamp(16px, 2.5vw, 40px)',
                        fontWeight: '400',
                        fontFamily: 'Inria Serif',
                        textAlign: 'left',
                        borderRadius: '10px',
                      }}
                    >
                      From its historical roots to its transformative impact on
                      our future, explore how AI shapes technology, society, and
                      culture.
                    </Card.Text>
                  </Col>

                  {/* Image Section */}
                  <Col xs={12} md={4} className='text-center'>
                    <img
                      src={RobotGirl} // Replace with actual image path
                      alt='Educational Programs'
                      width='150'
                      height='150'
                      className='img-fluid rounded-3'
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </section>
        {/* Second Section */}
        <section style={{ marginTop: '100px' }}>
          {/* Adjusted margin to ensure separation */}
          <div className='container'>
            <h2 className='text-center mb-5 text-white'>Lorem Ipsum</h2>
            <div className='row g-4 mb-5'>
              {/* Card 1 */}
              <div className='col-md-4'>
                <div
                  className='card h-100 bg-transparent border-light'
                  style={{
                    background:
                      'linear-gradient(180deg, #000000 0%, #270583 100%)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '20px 0 20px 0', // Upper-left and lower-right corners rounded
                    boxShadow: '4px 4px 60px 0px #8C8C8C',
                  }}
                >
                  <div className='card-body text-center'>
                    <div
                      className='rounded-circle p-3 d-inline-flex mb-3'
                      style={{
                        width: '80px',
                        height: '80px',
                        background: '#004B6B',
                      }}
                    >
                      {/* <i className='bi bi-lightbulb-fill text-white fs-4'></i> */}
                      <img
                        src={bulb} // Replace with actual image path
                        alt='bulb'
                        width={250}
                        height={250}
                        className='img-fluid rounded-3'
                      />
                    </div>
                    <h5 className='card-title text-white'>
                      Meet the future of AI
                    </h5>
                    <p className='card-text text-light'>
                      Our living robotics showcase
                    </p>
                  </div>
                </div>
              </div>

              {/* Card 2 */}
              <div className='col-md-4'>
                <div
                  className='card h-100 bg-transparent border-light'
                  style={{
                    background:
                      'linear-gradient(180deg, #000000 0%, #270583 100%)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '20px 0 20px 0',
                    boxShadow: '4px 4px 60px 0px #8C8C8C',
                  }}
                >
                  <div className='card-body text-center'>
                    <div
                      className='rounded-circle p-3 d-inline-flex mb-3'
                      style={{
                        width: '80px',
                        height: '80px',
                        background: '#004B6B',
                      }}
                    >
                      {/* <i className='bi bi-chat-fill text-white fs-4'></i> */}
                      <img
                        src={coder} // Replace with actual image path
                        alt='coder'
                        width={250}
                        height={250}
                        className='img-fluid rounded-3'
                      />
                    </div>
                    <h5 className='card-title text-white'>Code And Create</h5>
                    <p className='card-text text-light'>
                      Hands-on coding workshops for all ages.
                    </p>
                  </div>
                </div>
              </div>

              {/* Card 3 */}
              <div className='col-md-4'>
                <div
                  className='card h-100 bg-transparent border-light'
                  style={{
                    background:
                      'linear-gradient(180deg, #000000 0%, #270583 100%)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '20px 0 20px 0',
                    boxShadow: '4px 4px 60px 0px #8C8C8C',
                  }}
                >
                  <div className='card-body text-center'>
                    <div
                      className='rounded-circle p-3 d-inline-flex mb-3'
                      style={{
                        width: '80px',
                        height: '80px',
                        background: '#004B6B',
                      }}
                    >
                      {/* <i className='bi bi-globe text-white fs-4'></i> */}
                      <img
                        src={world}
                        alt='world'
                        width={250}
                        height={250}
                        className='img-fluid rounded-3'
                      />
                    </div>
                    <h5 className='card-title text-white'>AI For Good</h5>
                    <p className='card-text text-light'>
                      Real-life case studies solving global challenges.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div
          className='text-white'
          style={{
            background: 'linear-gradient(90deg, #3F1F55 0%, #1E1244 100%)',
          }}
        >
         
          <Container className='py-12'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
              <div>
                <h2 className='text-4xl font-bold mb-4'>
                  <span className='text-purple-400'>Join Over</span>
                  <br />
                  50,000 learners
                </h2>
                <p className='text-purple-300'>
                  With our super powers we have reached this
                </p>
              </div>

              <div className='grid grid-cols-2 gap-6'>
                {stats.map((stat, index) => (
                  <div key={index} className='flex items-start gap-3'>
                    <div className='w-8 h-8 bg-purple-500/20 rounded flex items-center justify-center'>
                      <div className='w-4 h-4 bg-purple-400'></div>
                    </div>
                    <div>
                      <div className='font-bold text-xl'>{stat.number}</div>
                      <div className='text-sm text-purple-300'>
                        {stat.label}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Container>
        </div> */}
        <div className='stats-section'>
          <div className='container py-5'>
            <div className='row align-items-center'>
              {/* Left Column - Heading */}
              <div className='col-lg-6 mb-4 mb-lg-0'>
                <h2
                  className='display-7 fw-bold mb-3'
                  style={{
                    backgroundImage:
                      'linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%)',
                    WebkitBackgroundClip: 'text', // Ensures gradient applies to text only
                    WebkitTextFillColor: 'transparent', // Makes the text transparent to show the gradient
                    backgroundClip: 'text', // Fallback for non-Webkit browsers
                    color: 'transparent', // Ensures fallback transparency
                  }}
                >
                  <span className='highlight-text'>
                    Join Over 50,000 Learners and
                  </span>
                  100+ Global Partners Driving AI Innovation.
                </h2>
                <p className='subtext'>
                Every Achievement is a Step Closer to Bringing the Digital Divide. Be Part of the Change.
                </p>
              </div>

              {/* Right Column - Stats Grid */}
              <div className='col-lg-6'>
                <div className='row g-4'>
                  {stats.map((stat, index) => (
                    <div key={index} className='col-6'>
                      <div className='d-flex align-items-start gap-3'>
                        {/* Square Icon */}
                        <div className='icon-wrapper'>
                          <img
                            src={stat.icon}
                            alt={stat.label}
                            className='icon-square'
                          />
                        </div>

                        {/* Stat Content */}
                        <div>
                          <div className='stat-number'>{stat.number}</div>
                          <div className='stat-label'>{stat.label}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Educational Programs Section */}
        <section
          className='container-fluid text-white text-center d-flex flex-column align-items-center py-5'
          style={{ background: '#181234' }}
        >
          <div
            className='container'
            style={{ background: '#3F1F55', borderRadius: '20px' }}
          >
            <div className='row align-items-center'>
              <div className='col-lg-4'>
                <img
                  src={face}
                  alt='face'
                  width={300}
                  height={300}
                  className='img-fluid rounded-3'
                />
              </div>
              <div className='col-lg-8'>
                <h2
                  className='display-5 mb-4 text-justify'
                  style={{
                    fontFamily: 'Inter',
                    fontSize: '45px',
                    fontWeight: '600',
                  }}
                >
                  Educational Programs And Workshops
                </h2>
                <p
                  className='lead text-justify'
                  style={{
                    fontFamily: 'Inter',
                    fontSize: '28px',
                    fontWeight: '400',
                  }}
                >
                  "Every Achievement Is A Step Closer To Bridging The Digital
                  Divide. Be Part Of The Change."
                </p>
                <button
                  className='btn px-4'
                  style={{
                    backgroundColor: '#FF7000',
                    borderColor: 'white',
                    color: 'white',
                    borderRadius: '20px',
                    marginBottom: '10px',
                  }}
                >
                  Get Started
                  <IoIosArrowForward
                    style={{
                      marginLeft: '4px',
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* CTA Section */}
        <section
          className='container-fluid bg-gradient-to-br from-purple-800 to-indigo-900 text-white py-5'
          style={{
            background:
              ' radial-gradient(217.23% 100.08% at 50% 18.33%, rgba(0, 0, 0, 0) 21.67%, #6633EE 69.34%, #FFFFFF 100%)',
          }}
        >
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-7'>
                <h2
                  className='display-5 fw-10 mb-4'
                  style={{
                    backgroundImage:
                      'linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%)',
                    WebkitBackgroundClip: 'text', // Ensures gradient applies to text only
                    WebkitTextFillColor: 'transparent', // Makes the text transparent to show the gradient
                    backgroundClip: 'text', // Fallback for non-Webkit browsers
                    color: 'transparent', // Ensures fallback transparency
                    fontFamily: 'Manrope',
                    fontSize: '60.34px',
                    fontWeight: '800',
                    textAlign: 'left',
                  }}
                >
                  The Future is Now and You Just Need To Realize It. Step into
                  Future Today & Make it Happen.
                </h2>
                <p className='lead mb-4' style={{ color: '#FF8A71' }}>
                  Request Early Access to Get Started
                </p>
                {/* <button className='btn btn-light px-4'>My App</button> */}
              </div>
              <div className='col-lg-5'>
                <img
                  src={girl}
                  alt='Future Technology'
                  width={600}
                  height={600}
                  className='img-fluid'
                />
              </div>
            </div>
          </div>
        </section>
        <div className='partners-section py-5'>
          <div className='container'>
            {/* Header */}
            <div className='row mb-4'>
              <div className='col-12'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <div className='highlight-bar mb-2'></div>
                    <h2 className='text-white mb-1 section-title'>
                      Meet the People
                    </h2>
                    <p className='text-white fw-bold section-subtitle'>
                      We are Working With
                    </p>
                  </div>
                  <button className='btn rounded-circle action-button'>
                    <BsPlus className='text-purple' size={20} />
                  </button>
                </div>
              </div>
            </div>

            {/* Partners Logos Scroll Section */}
            <div
              className='position-relative'
              style={{ background: '#3F1F55' }}
            >
              <div className='row flex-nowrap overflow-auto pb-3 scroll-container'>
                {partners.map((partner, index) => (
                  <div
                    key={index}
                    className='col-6 col-md-4 col-lg-3 scroll-item'
                  >
                    <div className='px-3'>
                      <div className='logo-wrapper'>
                        <img
                          src={partner.logo}
                          alt={`${partner.name} Logo`}
                          className='partner-logo'
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Main
