import React from 'react'
import Header from './Header'
import aimuseum from '../Assets/exhibition overview.mp4'
import demos from '../Assets/intaractiveDemos.mp4'
import tours from '../Assets/guided tours.mp4'
import walkthroughs from '../Assets/virtual walkthrough.mp4'
import '../Styles/Exhibit.css'
import happy from '../Assets/Assests/happy 1.svg'

const Exihibits = () => {
  return (
    <>
      <div className='about-page bg-dark text-white'>
        {/* Navigation */}
        <Header />

        {/* Vision & Mission Section */}
        <section className='vision-section'>
          <div className='container position-relative'>
            <div className='row'>
              <div className='col-lg-8'>
                <p className='lead mt-5'>AI & Robotics</p>
                <h1 className='display-3 mb-4'>
                  “Journey Through the Evolution of AI—From Imagination to
                  Real-World Impact.”
                </h1>
                <p className='lead'>
                  Immerse yourself in <b>interactive galleries</b> tracing the
                  roots of AI: from Alan Turing’s early computations to
                  contemporary neural networks powering voice assistants,
                  self-driving cars, and more.
                </p>
              </div>
            </div>
            <div className='geometric-shapes'></div>
          </div>
        </section>

        {/* History & Future Fusion Section */}
        <section className='history-section py-5'>
          <div className='container'>
            <h2 className='text-center mb-5'>AI Experiance</h2>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <div className='future-fusion-card p-4 rounded-lg'>
                  <div className='d-flex align-items-center mb-3'>
                    <div className='icon-circle me-3 d-flex justify-content-center align-items-center"'>
                      <img src={happy} alt='happy' />
                    </div>
                    <h3 className='mb-0'>Exhibition Overview</h3>
                  </div>
                  <p>
                    Discover the evolution of artificial intelligence, from its
                    earliest concepts to cutting-edge applications transforming
                    our world. Immerse yourself in galleries showcasing AI’s
                    journey through history, highlighting revolutionary
                    breakthroughs, innovations in robotics, and its growing
                    impact on society. Witness how AI shapes industries,
                    inspires creativity, and drives progress for the future.
                  </p>
                </div>
              </div>
              <div className='col-lg-6'>
                {/* <img
                  src={aimuseum}
                  alt='AI Museum'
                  className='img-fluid rounded-lg'
                /> */}
                <video
                  className='object-fit-cover w-100 h-100'
                  autoPlay
                  muted
                  loop
                  style={{ paddingTop: '50px' }} // Add padding above the video
                >
                  <source src={aimuseum} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </section>

        <section className='history-section py-5'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                {/* <img
                  src={demos}
                  alt='Interactive demos'
                  className='img-fluid rounded-lg'
                /> */}
                <video
                  className='object-fit-cover w-100 h-100'
                  autoPlay
                  muted
                  loop
                  style={{ paddingTop: '50px' }} // Add padding above the video
                >
                  <source src={demos} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className='col-lg-6'>
                <div className='future-fusion-card p-4 rounded-lg'>
                  <div className='d-flex align-items-center mb-3'>
                    <div className='icon-circle me-3 d-flex justify-content-center align-items-center"'>
                      <img src={happy} alt='happy' />
                    </div>
                    <h3 className='mb-0'>Interactive Demos</h3>
                  </div>

                  <p>
                    Engage with AI like never before through hands-on
                    interactive demos designed to captivate and educate visitors
                    of all ages. Dive into the cutting-edge world of Artificial
                    Intelligence as you experiment with virtual reality (VR) and
                    augmented reality (AR) technologies that blur the line
                    between the physical and digital realms. Interact with
                    machine learning models, understand how they work, and see
                    them evolve in real time to solve practical problems.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='history-section py-5'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <div className='future-fusion-card p-4 rounded-lg'>
                  <div className='d-flex align-items-center mb-3'>
                    <div className='icon-circle me-3 d-flex justify-content-center align-items-center"'>
                      <img src={happy} alt='happy' />
                    </div>
                    <h3 className='mb-0'>Guided Tours</h3>
                  </div>

                  <p>
                    Enhance your visit with immersive guided tours led by
                    passionate AI enthusiasts and seasoned experts. These tours
                    are crafted to provide a deeper understanding of the
                    incredible world of Artificial Intelligence. Experience
                    in-depth storytelling as guides unravel the history,
                    evolution, and real-world applications of AI, bringing
                    exhibits to life with engaging narratives and
                    thought-provoking insights. Ask questions and enjoy
                    personalized interactions, making each tour a unique
                    learning adventure.
                  </p>
                </div>
              </div>
              <div className='col-lg-6'>
                {/* <img src={tours} alt='Tours' className='img-fluid rounded-lg' /> */}
                <video
                  className='object-fit-cover w-100 h-100'
                  autoPlay
                  muted
                  loop
                  style={{ paddingTop: '50px' }} // Add padding above the video
                >
                  <source src={tours} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </section>

        <section className='history-section py-5'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                {/* <img
                  src={walkthroughs}
                  alt='Virtual Walkthroughs'
                  className='img-fluid rounded-lg'
                /> */}
                <video
                  className='object-fit-cover w-100 h-100'
                  autoPlay
                  muted
                  loop
                  style={{ paddingTop: '50px' }} // Add padding above the video
                >
                  <source src={walkthroughs} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className='col-lg-6'>
                <div className='future-fusion-card p-4 rounded-lg'>
                  <div className='d-flex align-items-center mb-3'>
                    <div className='icon-circle me-3 d-flex justify-content-center align-items-center"'>
                      <img src={happy} alt='happy' />
                    </div>
                    <h3 className='mb-0'>Vitual Walkthroughs</h3>
                  </div>

                  <p>
                    Can’t visit in person? Experience the AI Museum from
                    anywhere in the world with our immersive virtual
                    walkthroughs. Navigate the galleries in breathtaking
                    360-degree detail, offering a true sense of being there.
                    Access interactive hotspots embedded throughout the
                    exhibits, providing deeper insights into AI technologies,
                    their origins, and applications. Learn through engaging
                    multimedia content, including videos, audio guides, and
                    interactive features, designed to make your online visit
                    just as enriching as a physical one.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className='global-impact-section py-5 d-flex justify-content-center align-items-center'
          style={{ background: '#181234' }}
        >
          <div
            className='container text-center py-5'
            style={{
              background: '#3F1F55',
              borderRadius: '20px',
            }}
          >
            <div className='row justify-content-center align-items-center'>
              <div className='col-lg-8'>
                <h2
                  className='display-5 fw-5 mb-4'
                  style={{
                    backgroundImage:
                      'linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundClip: 'text',
                    color: 'transparent',
                  }}
                >
                  "AI R&D & Case Studies."
                </h2>
                <p className='lead mb-4'>
                  Explore AI innovation through our Research Labs, Publications,
                  Case Studies, and Showcases, highlighting groundbreaking
                  projects, real-world applications, and transformative
                  advancements shaping the future.
                </p>
                <button
                  className='btn px-4'
                  style={{
                    backgroundColor: '#FF7000',
                    borderColor: 'white',
                    color: 'white',
                    borderRadius: '20px',
                  }}
                >
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Exihibits
