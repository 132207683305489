import './App.css'
import About from './Components/About'
import Exihibits from './Components/Exihibits'
import GetInvolved from './Components/GetInvolved'
import LearnInnovate from './Components/LearnInnovate'

import Main from './Components/Main'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/about' element={<About />} />
        <Route path='/exhibits' element={<Exihibits />} />
        <Route path='/learn-innovate' element={<LearnInnovate />} />
        <Route path='/get-involved' element={<GetInvolved />} />
      </Routes>
    </Router>
  )
}

export default App
