// import React from 'react'
// import '../Styles/Footer.css'
// import { FaInstagram, FaLinkedin, FaFacebook } from 'react-icons/fa'
// import Logo from '../Assets/Assests/final_logo_AI museum.png'

// const Footer = () => {
//   return (
//     <footer className='container-fluid bg-dark text-white py-5'>
//       <div className='container'>
//         <div className='row g-4'>
//           <div className='col-lg-3'>
//             <div className='bg-light p-2 rounded'>
//               <img
//                 src={Logo}
//                 alt='Museum Logo'
//                 className='img-fluid'
//                 style={{
//                   maxWidth: '150px',
//                   height: 'auto',
//                   objectFit: 'cover',
//                   borderRadius: '10px',
//                   transition: 'transform 0.3s ease-in-out',
//                 }}
//                 onMouseEnter={(e) => (e.target.style.transform = 'scale(1.1)')}
//                 onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
//               />
//             </div>
//           </div>
//           <div className='col-lg-2'>
//             <h3 className='h6 mb-3'>INDUSTRIES</h3>
//             <ul className='list-unstyled'>
//               <li>Banking & Financial Services</li>
//               <li>Energy & Commodities</li>
//               <li>Healthcare</li>
//               <li>Public Sector</li>
//               <li>Retail</li>
//               <li>Telecommunications Media & Technology</li>
//               <li>Transportation & Mobility</li>
//             </ul>
//           </div>
//           <div className='col-lg-2'>
//             <h4>SERVICES</h4>
//             <ul>
//               <li>Business Transformation</li>
//               <li>Digital Transformation</li>
//               <li>Learning Transformation</li>
//               <li>Technology Training Programs</li>
//             </ul>
//           </div>
//           <div className='col-lg-2'>
//             <h4>QUICK LINKS</h4>
//             <ul>
//               <li>Privacy Policy</li>
//               <li>Refund Policy</li>
//               <li>Terms & Conditions</li>
//               <li>Blogs</li>
//             </ul>
//           </div>
//           <div className='col-lg-2'>
//             <h4>INITIATIVES</h4>
//             <ul>
//               <li>Panther Diaries</li>
//             </ul>
//           </div>
//           <div className='col-lg-2'>
//             <h3 className='h6 mb-3'>GET IN TOUCH, ENQUIRE NOW</h3>
//             <form>
//               <div className='input-group mb-3'>
//                 <input
//                   type='email'
//                   className='form-control'
//                   placeholder='Email Address'
//                 />
//                 <button className='btn btn-primary' type='submit'>
//                   Submit
//                 </button>
//               </div>
//             </form>

//             <div className='footer-social'>
//               <a href='/about-us'>About Us</a>
//               <a href='#'>Career</a>
//               <a href='#'>Contact Us</a>
//               <div className='social-icons'>
//                 <a href='#'>
//                   <FaFacebook />
//                 </a>
//                 <a href='https://www.linkedin.com/in/aiglobalmuseum-madan-5a598233b/overlay/about-this-profile/'>
//                   <FaLinkedin />
//                 </a>
//                 <a href='https://www.instagram.com/aiglobalmuseum/#'>
//                   <FaInstagram />
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </footer>
//   )
// }

// export default Footer

import React from 'react'
import '../Styles/Footer.css'
import { FaInstagram, FaLinkedin, FaFacebook } from 'react-icons/fa'
import Logo from '../Assets/Assests/final_logo_AI museum.png'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer className='footer bg-dark text-white py-5'>
      <div className='container'>
        <div className='row g-4'>
          {/* Industries Column */}
          <div className='col-12 col-sm-6 col-lg'>
            <h3 className='h6 mb-3'>INDUSTRIES</h3>
            <ul className='list-unstyled small'>
              <li className='mb-2'>Banking & Financial Services</li>
              <li className='mb-2'>Energy & Commodities</li>
              <li className='mb-2'>Healthcare</li>
              <li className='mb-2'>Public Sector</li>
              <li className='mb-2'>Retail</li>
              <li className='mb-2'>Telecommunications Media & Technology</li>
              <li className='mb-2'>Transportation & Mobility</li>
            </ul>
          </div>

          {/* Services Column */}
          <div className='col-12 col-sm-6 col-lg'>
            <h3 className='h6 mb-3'>SERVICES</h3>
            <ul className='list-unstyled small'>
              <li className='mb-2'>Business Transformation</li>
              <li className='mb-2'>Digital Transformation</li>
              <li className='mb-2'>Learning Transformation</li>
              <li className='mb-2'>Technology Training Programs</li>
            </ul>
          </div>

          {/* Quick Links Column */}
          <div className='col-12 col-sm-6 col-lg'>
            <h3 className='h6 mb-3'>QUICK LINKS</h3>
            <ul className='list-unstyled small'>
              <li className='mb-2'>Privacy Policy</li>
              <li className='mb-2'>Refund Policy</li>
              <li className='mb-2'>Terms & Conditions</li>
              <li className='mb-2'>Blogs</li>
            </ul>
          </div>

          {/* Initiatives Column */}
          <div className='col-12 col-sm-6 col-lg'>
            <h3 className='h6 mb-3'>INITIATIVES</h3>
            <ul className='list-unstyled small'>
              <li className='mb-2'>Panther Diaries</li>
              <li className='mb-2'>Subscribe HR</li>
            </ul>
          </div>

          {/* Contact Column */}
          <div className='col-12 col-lg'>
            <div className='d-flex flex-wrap gap-3 mb-4'>
              <Link
                href='/about-us'
                className='text-white text-decoration-none small'
              >
                ABOUT US
              </Link>
              <Link
                href='/career'
                className='text-white text-decoration-none small'
              >
                CAREER
              </Link>
              <Link
                href='/contact'
                className='text-white text-decoration-none small'
              >
                CONTACT US
              </Link>
            </div>
            <div className='d-flex gap-3 mb-4'>
              <Link href='#' className='text-white hover-purple'>
                <FaFacebook size={20} />
              </Link>
              <Link href='#' className='text-white hover-purple'>
                <FaLinkedin size={20} />
              </Link>
              <Link href='#' className='text-white hover-purple'>
                <FaLinkedin size={20} />
              </Link>
            </div>
          </div>
        </div>

        {/* Contact Form Section */}
        <div className='row mt-5 pt-4'>
          <div className='col-12 text-center mb-4'>
            <div className='bg-light p-2 rounded'>
              <img
                src={Logo}
                alt='Museum Logo'
                className='img-fluid'
                style={{
                  maxWidth: '150px',
                  height: 'auto',
                  objectFit: 'cover',
                  borderRadius: '10px',
                  transition: 'transform 0.3s ease-in-out',
                }}
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.1)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </div>
          </div>
          <div className='col-12 text-center mb-4'>
            <h3 className='h6'>GET IN TOUCH, ENQUIRE NOW</h3>
          </div>
          <div className='col-12'>
            <form>
              <div className='row justify-content-center g-3'>
                <div className='col-12 col-md-4'>
                  <input
                    type='text'
                    className='form-control bg-transparent text-white border-white'
                    placeholder='Full Name'
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <input
                    type='email'
                    className='form-control bg-transparent text-white border-white'
                    placeholder='Email'
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <input
                    type='tel'
                    className='form-control bg-transparent text-white border-white'
                    placeholder='Phone No.'
                  />
                </div>
                <div className='col-12 text-center mt-4'>
                  <button className='btn btn-purple px-4' type='submit'>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className='col-12 text-center mt-4'>
            <p className='small text-white'>
              By submitting this form, I consent to the processing of the
              personal data that I provide The DataTech Labs Inc. in accordance
              with and as described in the Privacy Policy and Terms & Conditions
            </p>
            <p className='small text-white mt-2'>
              The DataTech Labs Inc. All rights reserved ©2023
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
