import React from 'react'
import Header from './Header'
import man from '../Assets/Assests/man-320276.svg'
import news from '../Assets/Assests/freepik__the-style-is-candid-image-photography-with-natural__63944 1.svg'
import event from '../Assets/Assests/freepik__the-style-is-candid-image-photography-with-natural__63945 1.svg'
import member from '../Assets/Assests/freepik__the-style-is-candid-image-photography-with-natural__63946 1.svg'
import volunteer from '../Assets/Assests/freepik__the-style-is-candid-image-photography-with-natural__63947 3.svg'
import team from '../Assets/Assests/freepik__the-style-is-candid-image-photography-with-natural__63948 3 (1).svg'
import happy from '../Assets/Assests/happy 1.svg'

const GetInvolved = () => {
  return (
    <>
      <div className='about-page text-white' style={{ background: '#010314' }}>
        {/* Navigation */}
        <Header />

        {/* Vision & Mission Section */}
        <section className='vision-section'>
          <div className='container position-relative'>
            <div className='row'>
              <div className='col-lg-8'>
                <p className='lead mt-5'>AI & Robotics</p>
                <h1 className='display-3 mb-4'>
                  “Stay Curious: Get the Latest on AI Trends, Insights, and
                  Innovations.”
                </h1>
                <p className='lead'>
                  Stay curious and dive into the ever-evolving world of
                  artificial intelligence. Discover the latest trends,
                  groundbreaking innovations, and insightful perspectives
                  shaping the AI landscape. Explore expert analyses, industry
                  updates, and cutting-edge developments that illuminate AI's
                  potential to transform industries, enhance lives, and address
                  global challenges with creativity and precision.
                </p>
              </div>
            </div>
            <div className='geometric-shapes'></div>
          </div>
        </section>

        {/* News & Events Section */}
        <section
          className='container-fluid py-5'
          style={{ background: '#06071B' }}
        >
          <div className='container'>
            <h2 className='text-center text-white mb-5'>News & Events</h2>

            {/* Spotlight */}
            <div
              className='card mb-5'
              style={{
                background: '#010314',
                borderWidth: '1.8px, 0px, 1.8px, 0px',

                borderStyle: 'solid',

                borderImageSource:
                  'linear-gradient(95.02deg, #FFFFFF 13.23%, #7241FF 81.63%)',
              }}
            >
              <div className='row g-0'>
                <div className='col-md-8'>
                  <div className='card-body'>
                    <h3 className='card-title text-white'>
                      In the Spotlight: The AI Museum Leading the Conversation
                    </h3>
                    <p className='card-tex text-white'>
                      In the spotlight, the AI Museum takes center stage in this
                      global conversation on artificial intelligence. Through
                      groundbreaking innovations and collaborative initiatives,
                      we continue to shape the future of technology, ethics, and
                      humanity.
                    </p>
                  </div>
                </div>
                <div className='col-md-4'>
                  <img
                    src={news}
                    alt='AI Museum event'
                    className='img-fluid rounded'
                  />
                </div>
              </div>
            </div>

            {/* Upcoming Event */}
            <div
              className='card bg-transparent text-light'
              style={{ border: '0.9px solid #FFFFFF' }}
            >
              <div className='row g-0'>
                <div className='col-md-6'>
                  <img
                    src={event}
                    alt='VR Experience'
                    className='img-fluid rounded'
                  />
                </div>
                <div className='col-md-6'>
                  <div className='card-body'>
                    <h3 className='card-title text-white'>
                      Save the Date—Join Us in Shaping the Future
                    </h3>
                    <div className='mb-3'>
                      <p className='mb-1'>
                        <strong>Date:</strong> 12 Aug 2025
                      </p>
                      <p className='mb-3'>
                        <strong>Location:</strong> Singapore
                      </p>
                    </div>
                    <p className='card-text'>
                      Participate in engaging conferences, thrilling hackathons,
                      insightful seminars, interactive exhibits, and dynamic
                      networking sessions with fellow AI enthusiasts and
                      experts.
                    </p>
                    <button
                      className='btn px-4'
                      style={{
                        backgroundColor: '#FF7000',
                        borderColor: 'white',
                        color: 'white',
                        borderRadius: '20px',
                      }}
                    >
                      Register Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className='container-fluid py-5'
          style={{ background: 'linear-gradient(to bottom, #1a1a2e, #16213e)' }}
        >
          <div
            className='container'
            style={{ border: '0.9px solid #FFFFFF', borderRadius: '20px' }}
          >
            <div className='row align-items-center'>
              <div className='col-lg-12 text-center'>
                <h1
                  className='display-5 fw-10 mb-4'
                  style={{
                    backgroundImage:
                      'linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%)',
                    WebkitBackgroundClip: 'text', // Ensures gradient applies to text only
                    WebkitTextFillColor: 'transparent', // Makes the text transparent to show the gradient
                    backgroundClip: 'text', // Fallback for non-Webkit browsers
                    color: 'transparent', // Ensures fallback transparency
                  }}
                >
                  Amplify Our Mission—Become a Member Today
                </h1>
                <div className='position-relative'>
                  <img
                    src={member}
                    alt='People working with AI'
                    className='img-fluid rounded mb-4'
                  />
                  <p className='fw-3 mb-4'>
                    "Your membership fuels transformative education, drives
                    groundbreaking innovation, and fosters social good. By
                    joining, you support AI learning, empower communities, and
                    contribute to a future shaped by ethical technology."
                  </p>
                  <button
                    className='btn px-4'
                    style={{
                      backgroundColor: '#FF7000',
                      borderColor: 'white',
                      color: 'white',
                      borderRadius: '20px',
                      marginBottom: '10px',
                    }}
                  >
                    Become a Member
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Support Section */}
        <section
          className='container-fluid py-5'
          style={{ background: '#16213e' }}
        >
          <div className='container'>
            <div className='d-flex align-items-center mb-3'>
              <div className='icon-circle me-3 d-flex justify-content-center align-items-center"'>
                <img src={happy} alt='happy' />
              </div>
              <h3 className='mb-0'>
                Fuel the Future: Support AI Education and Innovation
              </h3>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='card bg-dark text-light border-0'>
                  <div className='card-body'>
                    <h5 className='card-title'>
                      Suggested amounts and program tie-ins:
                    </h5>
                    <ul className='list-unstyled'>
                      <li className='mb-2'>
                        • $100 funds a student's hands-on AI workshop
                      </li>
                      <li className='mb-2'>
                        • $500 enables the establishment of an AI outreach lab
                      </li>
                      <li className='mb-2'>
                        • $1,000 sponsors a community innovation program
                      </li>
                    </ul>
                    <div className='mt-4'>
                      <h5>Recurring Donations:</h5>
                      <p>
                        Commit to a monthly pledge that ensures the museum's
                        programs, outreach efforts, and research initiatives
                        continue to thrive throughout the year.
                      </p>
                    </div>
                  </div>
                  <div className='geometric-shapes'></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className='global-impact-section py-5'
          style={{ background: '#181234' }}
        >
          <div
            className='container'
            style={{ background: '#3F1F55', borderRadius: '20px' }}
          >
            <div className='row align-items-center align-content-center'>
              <div className='col-lg-8 '>
                <h2 className='display-5'>
                  “Together, We Can Shape a Smarter, More Equitable World.”
                </h2>
                <p className='lead'>
                  "Together, we can shape a smarter, more equitable world by
                  harnessing the power of AI. Join us in creating opportunities,
                  driving innovation, and making technology accessible to all."
                </p>
              </div>
              <div className='col-lg-4'>
                <img src={man} alt='Robot illustration' className='img-fluid' />
              </div>
            </div>
          </div>
        </section>

        {/* Volunteer Section */}
        <section
          className='container-fluid py-5'
          style={{ background: '#1a1a2e' }}
        >
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <h2 className='text-white mb-4'>Volunteer & Ambassadorship</h2>
                <h3 className='h4 mb-3'>
                  "Be the Voice of Change—Share Your Time, Knowledge, and
                  Passion"
                </h3>
                <p className='mb-4'>
                  Together, we can shape a smarter, more equitable world by
                  unlocking the transformative potential of AI. Your commitment
                  to innovation builds bridges and empowers communities.
                </p>
                <button
                  className='btn px-4'
                  style={{
                    backgroundColor: '#FF7000',
                    borderColor: 'white',
                    color: 'white',
                    borderRadius: '20px',
                  }}
                >
                  Register Now
                </button>
              </div>
              <div className='col-lg-6'>
                <img src={volunteer} className='img-fluid rounded' />
              </div>
            </div>
          </div>
        </section>

        <section
          className='container-fluid py-5'
          style={{ background: 'linear-gradient(to bottom, #1a1a2e, #16213e)' }}
        >
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-12 text-center'>
                <h1
                  className='display-5 fw-10 mb-4'
                  style={{
                    backgroundImage:
                      'linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%)',
                    WebkitBackgroundClip: 'text', // Ensures gradient applies to text only
                    WebkitTextFillColor: 'transparent', // Makes the text transparent to show the gradient
                    backgroundClip: 'text', // Fallback for non-Webkit browsers
                    color: 'transparent', // Ensures fallback transparency
                  }}
                >
                  “Join a Team of Trailblazers: Innovate with Us.”
                </h1>
                <div className='position-relative'>
                  <img src={team} className='img-fluid rounded mb-4' />
                  <p className='lead mb-4'>
                    "Your membership fuels transformative education, drives
                    groundbreaking innovation, and fosters social good. By
                    joining, you support AI learning, empower communities, and
                    contribute to a future shaped by ethical technology."
                  </p>
                  <button
                    className='btn px-4'
                    style={{
                      backgroundColor: '#FF7000',
                      borderColor: 'white',
                      color: 'white',
                      borderRadius: '20px',
                    }}
                  >
                    Become a Member
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section
          className='container-fluid py-5'
          style={{ background: '#1a1a2e' }}
        >
          <div className='container'>
            <h2 className='text-center text-white mb-5'>FAQ's</h2>
            <div className='accordion' id='faqAccordion'>
              {[
                'Who can participate?',
                'What is registration fee?',
                'Can I participate in both events?',
                'Will I be able to embed the chatbot into my website?',
                'Can multiple team members in my organization manage my chatbots?',
              ].map((question, index) => (
                <div
                  className='accordion-item bg-dark text-light border-bottom border-secondary'
                  key={index}
                >
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button collapsed bg-dark text-light'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#collapse${index}`}
                    >
                      {question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className='accordion-collapse collapse'
                    data-bs-parent='#faqAccordion'
                  >
                    <div className='accordion-body'>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* Contact Section */}
        <section
          className='container-fluid py-5'
          style={{ background: '#16213e' }}
        >
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-8'>
                <div
                  className='card text-light border-0 rounded-4'
                  style={{
                    background:
                      'linear-gradient(91.57deg, #5C2ED7 1.34%, #000000 147.36%)',
                  }}
                >
                  <div className='card-body p-4'>
                    <h2 className='card-title mb-4'>Contact Us</h2>
                    <p className='mb-4'>
                      Have questions? Reach out to us at Email Address or call
                      us at 989898989
                    </p>
                    <div className='input-group'>
                      <input
                        type='email'
                        className='form-control'
                        placeholder='Enter your email address'
                      />
                      <button
                        className='btn btn-warning'
                        style={{
                          background:
                            'linear-gradient(101.91deg, #8454BC -27.87%, #000000 185.73%)',
                        }}
                        type='button'
                      >
                        Sign up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default GetInvolved
