import React, { useState } from 'react'
import '../Styles/About.css'
import Header from './Header'
import vision from '../Assets/Assests/freepik__the-style-is-candid-image-photography-with-natural__48286 2.svg'
import origin from '../Assets/Assests/freepik__the-style-is-candid-image-photography-with-natural__48287 1.svg'
import man from '../Assets/Assests/man-320276.svg'
import face from '../Assets/mesume/Header Illustration.png'
import Footer from './Footer'
import position from '../Assets/Assests/Group 1171275863.svg'
import happy from '../Assets/Assests/happy 1.svg'

const About = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  const fullText = `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex Lorem ipsum dolor sit amet,
    consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip exLorem ipsum dolor sit amet, consectetur adipiscing elit.
    Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
    ullamco laboris nisi ut aliquip exLorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor
    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
    ut aliquip excididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
    laboris nisi ut aliquip exLorem ipsum dolor sit amet, consectetur adipiscing elit.
  `

  const truncatedText = `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex Lorem ipsum dolor sit amet,
    consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,...
  `
  return (
    <>
      <div className='about-page bg-dark text-white'>
        {/* Navigation */}
        <Header />

        {/* Vision & Mission Section */}
        <section className='vision-section'>
          <div className='container position-relative'>
            <div className='row'>
              <div className='col-lg-8'>
                <p className='lead mt-5'>AI & Robotics</p>
                <h1 className='display-4 mb-4'>
                  "Empowering Every Mind to Innovate: AI for All, AI for Good."
                </h1>
                <p className='lead'>
                  At the AI Global Museum, our vision is to make artificial
                  intelligence accessible, understandable, and impactful for all
                  individuals. Inspired by philanthropic organizations like the
                  Nilekani Foundation and the Melinda Gates Foundation, we aim
                  to harness technology as a force for good, nurturing talent
                  and driving innovation that positively impacts communities
                  worldwide.
                  <br /> The museum aspires to be a leading institution that
                  highlights the latest advancements in AI and its diverse
                  applications. Recognizing AI’s transformative potential, we
                  are committed to addressing global challenges in alignment
                  with the United Nations Sustainable Development Goals (SDGs).
                  Our focus will be on Education, Mental Wellness, Inspiration,
                  and Global Collaboration, all designed to benefit communities
                  around the world. The museum is dedicated to promoting
                  equality and diversity in education while raising awareness
                  about environmental conservation, particularly the protection
                  of local lakes. Through these efforts, we aim to create a
                  lasting, meaningful impact on both society and the planet.
                </p>
              </div>
            </div>
            <div className='geometric-shapes'></div>
          </div>
        </section>

        {/* History & Future Fusion Section */}
        <section className='history-section py-5'>
          <div className='container'>
            <h2 className='text-center mb-5'>"History & Origin"</h2>
            <p className='subtext'>
              Born from a Dream: A World Where Technology Meets Humainity.
            </p>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <div className='future-fusion-card p-4 rounded-lg'>
                  <div className='d-flex align-items-center mb-3'>
                    <div className='icon-circle me-3 d-flex justify-content-center align-items-center"'>
                      <img src={happy} alt='happy' />
                    </div>
                    <h3 className='mb-0'>Future Fusion</h3>
                  </div>
                  <h4>What is our Vision?</h4>
                  <p>
                    Born from a dream: a world where technology meets humanity,
                    weaving innovation with empathy. Here, machines amplify
                    human potential, bridging gaps and fostering connections.
                    Artificial intelligence learns compassion; robotics embody
                    care, and digital solutions empower communities.
                  </p>
                </div>
              </div>
              <div className='col-lg-6'>
                <img
                  src={vision}
                  alt='AI Vision Illustration'
                  className='img-fluid rounded-lg'
                />
              </div>
            </div>
          </div>
        </section>

        <section className='history-section py-5'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <img
                  src={origin}
                  alt='AI Origin'
                  className='img-fluid rounded-lg'
                />
              </div>
              <div className='col-lg-6'>
                <div className='future-fusion-card p-4 rounded-lg'>
                  <div className='d-flex align-items-center mb-3'>
                    <div className='icon-circle me-3 d-flex justify-content-center align-items-center"'>
                      <img src={happy} alt='happy' />
                    </div>
                    <h3 className='mb-0'>Vision</h3>
                  </div>
                  <h4>What is Our origin & purpose?</h4>
                  <p>
                    The AI Museum was conceived by a passionate group of
                    researchers, educators, and social entrepreneurs who
                    believed in bridging the gap between AI potential and public
                    understanding. Driven by the philanthropic ethos of
                    empowering the underserved and amplifying positive impact,
                    we continue to evolve with a singular purpose: to nurture
                    curiosity, spark creativity, and champion ethical AI
                    solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Vision & Mission Section */}
        <section
          className='vision-section'
          style={{
            borderTop: '2.31px solid #664DFF',
          }}
        >
          <div className='container position-relative'>
            <h2
              className='display-5 fw-5 mb-4'
              style={{
                backgroundImage:
                  'linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%)',
                WebkitBackgroundClip: 'text', // Ensures gradient applies to text only
                WebkitTextFillColor: 'transparent', // Makes the text transparent to show the gradient
                backgroundClip: 'text', // Fallback for non-Webkit browsers
                color: 'transparent', // Ensures fallback transparency
              }}
            >
              “Guided by Visionaries, United by Purpose.”
            </h2>
            <div className='row d-flex align-items-center'>
              <div className='col-lg-8'>
                <p className='lead'>{isExpanded ? fullText : truncatedText}</p>
                <button
                  onClick={() => setIsExpanded(!isExpanded)}
                  className='btn btn-link p-0'
                  style={{ textDecoration: 'none' }}
                >
                  {isExpanded ? 'Read Less' : 'Read More'}
                </button>
              </div>
              <div className='col-lg-4'>
                <div className='man-frame'></div>
              </div>
            </div>
          </div>
        </section>

        {/* Board Members Section */}
        <section
          className='board-section py-5'
          style={{
            background: `linear-gradient(90deg, #3F1F55 0%, #1E1244 100%)
            radial-gradient(251.01% 115.64% at 50% -1.43%, rgba(0, 0, 0, 0) 51.58%, #6633EE 87.11%, #FFFFFF 100%)`,
            border: ' 1.16px solid #664DFF',
          }}
        >
          <div className='container text-center'>
            <h2 className='mb-4'>MEET THE BOARD MEMBERS</h2>
            <p className='mb-5'>
              LOREM IPSUM DOLOR SIT AMET, AUT VOLUPTAS PARIATUR ET ALIQUID
            </p>
            <div className='row justify-content-center'>
              {[1, 2, 3, 4].map((member) => (
                <div key={member} className='col-6 col-md-3 mb-4'>
                  <div className='board-member'>
                    <img
                      src={position}
                      alt={`Board Member ${member}`}
                      className='rounded-circle mb-3'
                      width='150'
                      height='150'
                    />
                    <h5>Person Name</h5>
                    <p className='text-white'>Position</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Global Impact Section */}
        <section
          className='global-impact-section py-5'
          style={{ background: '#181234', border: ' 1.16px solid #664DFF' }}
        >
          <div
            className='container'
            style={{ background: '#3F1F55', borderRadius: '20px' }}
          >
            <div className='row align-items-center'>
              <div className='col-lg-8'>
                <h2 className='display-5'>"Global Thinkers, Local Impact."</h2>
                <p className='lead'>
                  "Our Advisory Council Comprises AI Pioneers, Tech Innovators,
                  And Seasoned Educators Committed To Guiding Our Research,
                  Exhibitions, And Programs."
                </p>
              </div>
              <div className='col-lg-4'>
                <img src={man} alt='Robot illustration' className='img-fluid' />
              </div>
            </div>
          </div>
        </section>

        {/* Transparency Section */}
        <section
          className='text-white text-center d-flex flex-column align-items-center mt-5 py-5'
          style={{ background: 'rgba(0, 0, 0, 0.2);' }}
        >
          <div
            className='container'
            style={{ background: '#3F1F55', borderRadius: '20px' }}
          >
            <div className='row align-items-center'>
              <div className='col-lg-4'>
                <img
                  src={face}
                  alt='face'
                  width={300}
                  height={300}
                  className='img-fluid rounded-3'
                />
              </div>
              <div className='col-lg-8'>
                <h2 className='display-5 mb-4 text-justify'>
                  Transparency Fuels Trust
                </h2>
                <p className='lead text-justify'>
                  "Discover Our Inspiring Journey, Groundbreaking Milestones,
                  And Visionary Plans For The Future As We Continue To Educate,
                  Innovate, And Empower Communities Through The Transformative
                  Power Of Artificial Intelligence."
                </p>
                <button
                  className='btn px-4'
                  style={{
                    backgroundColor: '#FF7000',
                    borderColor: 'white',
                    color: 'white',
                    borderRadius: '20px',
                    marginBottom: '10px',
                  }}
                >
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}

export default About
